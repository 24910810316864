import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { GRAPHQL_ENDPOINT } from './constants';

const client = new ApolloClient({
  uri: GRAPHQL_ENDPOINT,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;
