import { gql } from '@apollo/client/core';
import client from '../util/graphql';

const QUERIES = {
  GET_WEB_MESSENGER_CONFIG: gql`
    query GetWebMessengerConfig {
      webMessengerConfig {
        config {
          TYPE
          PAGES {
            PAGE
          }
          SCRIPTS {
            SCRIPT
          }
          SCHEDULES {
            TYPE
            START_TIME
            END_TIME
          }
        }
        holidays {
          DATE
        }
      }
    }
  `,
};

const getWebMessengerConfig = async () => {
  try {
    const { data } = await client.query({
      query: QUERIES.GET_WEB_MESSENGER_CONFIG,
    });
    return data;
  } catch (err) {
    console.error(err.message);
  }
};

export default getWebMessengerConfig;
