const APP_URL = window.location.href;
const PATH_NAME = window.location.pathname;
const LAUNCHER_HTMLFILE_PATH = '/webmessenger/launcher/launcher.html';
const LAUNCHER_CSSFILE_PATH = '/webmessenger/launcher/css/genesys.css';
const LAUNCHER_CONSUMER_SALES_CSSFILE_PATH =
  '/webmessenger/launcher/css/genesys-chat-eir-consumer-sales.css';
const LAUNCHER_CONSUMER_SERVICE_CSSFILE_PATH =
  '/webmessenger/launcher/css/genesys-chat-eir-consumer-service.css';
const LAUNCHER_SMB_SALES_CSSFILE_PATH = '/webmessenger/launcher/css/genesys-chat-eir-smb-sales.css';

const DEV_ENV = {
  url: {
    DOMAIN_URL: window.location.origin,
  },
};

const LOCAL_ENV = {
  url: {
    DOMAIN_URL: 'https://testwww02.eir.ie/',
  },
};

const PROD_ENV = {
  url: {
    DOMAIN_URL: window.location.origin,
  },
};

const GET_DOMAIN = () => {
  if (APP_URL.indexOf('testwww') > 0) {
    return DEV_ENV.url.DOMAIN_URL;
  }
  if (APP_URL.indexOf('localhost') > 0) {
    return LOCAL_ENV.url.DOMAIN_URL;
  }
  return PROD_ENV.url.DOMAIN_URL;
};

module.exports = {
  GRAPHQL_ENDPOINT:
    window.location.hostname === 'localhost'
      ? 'https://online-api-gateway.dev.webmail.comhar.local/graphql'
      : `${window.location.origin}/graphql/`,

  GET_DOMAIN,
  APP_URL,
  PATH_NAME,
  LAUNCHER_HTMLFILE_PATH,
  LAUNCHER_CSSFILE_PATH,
  LAUNCHER_CONSUMER_SALES_CSSFILE_PATH,
  LAUNCHER_CONSUMER_SERVICE_CSSFILE_PATH,
  LAUNCHER_SMB_SALES_CSSFILE_PATH,
};
