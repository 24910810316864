import { PATH_NAME } from '../util/constants';

let currentPagePathName = PATH_NAME;
const now = new Date();

// Function to check if dst is in effect or not
export const isDSTCheck = (dstStartDate, dstEndDate, currDate) => {
  const startDate = Date.parse(dstStartDate);
  const endDate = Date.parse(dstEndDate);
  const checkDate = Date.parse(currDate);
  if (checkDate <= endDate && checkDate >= startDate) {
    console.log('DST is in Effect!!!', 'DST-StartDate:', dstStartDate, 'DST-EndDate:', dstEndDate);
    return true;
  }
  console.log(
    'DST is not in Effect!!!',
    'DST-StartDate:',
    dstStartDate,
    'DST-EndDate:',
    dstEndDate
  );
  return false;
};

// Function to get current page path name
export const getPathName = () => {
  if (currentPagePathName.endsWith('/') === false) {
    currentPagePathName = currentPagePathName.concat('/');
  }
  if (currentPagePathName.includes('/opencms/export/sites/default')) {
    return currentPagePathName.split('/opencms/export/sites/default')[1];
  }
  return currentPagePathName;
};

// Function to convert time to seconds
export const timeToSeconds = time => {
  const actualTime = time.split(':');
  const totalSeconds = +actualTime[0] * 60 * 60 + +actualTime[1] * 60 + +actualTime[2];
  return totalSeconds;
};

// Function to get current year, month and date in '2023-05-31' format
export const getCurrentDate = () => {
  let month;
  let date;
  if (now.getMonth() + 1 >= 1 && now.getMonth() + 1 <= 9) {
    month = `0${now.getMonth() + 1}`;
  } else {
    month = now.getMonth() + 1;
  }
  if (now.getDate() >= 1 && now.getDate() <= 9) {
    date = `0${now.getDate()}`;
  } else {
    date = now.getDate();
  }
  return `${now.getFullYear()}-${month}-${date}`;
};

export const loadHtmlPage = href => {
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('GET', href, false);
  xmlhttp.send();
  return xmlhttp.responseText;
};
