import getWebMessengerConfig from '../integration/genesys.api';
import {
  GET_DOMAIN,
  LAUNCHER_HTMLFILE_PATH,
  LAUNCHER_CSSFILE_PATH,
  LAUNCHER_CONSUMER_SALES_CSSFILE_PATH,
  LAUNCHER_CONSUMER_SERVICE_CSSFILE_PATH,
  LAUNCHER_SMB_SALES_CSSFILE_PATH,
} from '../util/constants';
import dayLightSavingsData from '../json_files/daylightsavingdata.json';
import {
  isDSTCheck,
  getPathName,
  timeToSeconds,
  getCurrentDate,
  loadHtmlPage,
} from '../service/genesys.service';

const domainUrl = GET_DOMAIN();
const genesysScript = document.createElement('script');
const launcher = document.createElement('div');
const genesysCsslink = document.createElement('link');
const genesysScriptCsslink = document.createElement('link');
genesysCsslink.href = `${domainUrl}${LAUNCHER_CSSFILE_PATH}`;
genesysCsslink.type = 'text/css';
genesysCsslink.rel = 'stylesheet';
genesysScriptCsslink.type = 'text/css';
genesysScriptCsslink.rel = 'stylesheet';

const getScripts = async () => {
  try {
    const response = await getWebMessengerConfig();
    const config = response?.webMessengerConfig?.config;
    const holidays = response?.webMessengerConfig?.holidays;
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const date = now.getDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();
    const day = now.getUTCDay();
    const currDate = `${month}/${date}/${year}`;
    const dstStartDate = dayLightSavingsData[year].start_date;
    const dstEndDate = dayLightSavingsData[year].end_date;
    let scriptList = null;
    let filteredConfig = null;
    let schedule = null;
    let currTime = null;

    // If DST is in effect, add 1 hour to currentTime
    if (isDSTCheck(dstStartDate, dstEndDate, currDate)) {
      // If currentTime is 24th hour, don't add 1 instead, make it to 1
      currTime =
        hours === 24
          ? (currTime = `${1}:${minutes}:${seconds}`)
          : (currTime = `${hours + 1}:${minutes}:${seconds}`);
    } else {
      currTime = `${hours}:${minutes}:${seconds}`;
    }

    const pathName = getPathName();

    // Filter the config, which matches current page pathName
    config.forEach(object => {
      const routes = object.PAGES.map(p => p.PAGE);
      if (routes.includes(pathName)) {
        filteredConfig = object;
      }
    });

    // For filtered config, check if all startTime and endTime is null in schedule array
    const areDatesNull = filteredConfig?.SCHEDULES.every(
      s => s.START_TIME === null && s.END_TIME === null
    );

    // If all startTime and endTime are null, than insert the script 24/7
    if (areDatesNull) {
      // insert and early return
      scriptList = filteredConfig.SCRIPTS.map(s => s.SCRIPT);
    } else {
      // If all startTime and endTime are not null, select the schedule type depending on current day i.e.(WORKDAY, WEEKEND, HOLIDAY)
      const holidaysList = holidays.map(holiday => holiday.DATE);

      // Added 1 to now.getMonth(), as index starts from 0 (jan-0, feb-1,...,dec-11)
      if (holidaysList.includes(getCurrentDate())) {
        console.log(
          'Holiday schedule',
          filteredConfig.SCHEDULES.filter(e => e.TYPE === 'HOLIDAY')
        );
        schedule = filteredConfig.SCHEDULES.filter(e => e.TYPE === 'HOLIDAY');
      } else if (day === 0 || day === 6) {
        console.log(
          'Weekend schedule',
          filteredConfig.SCHEDULES.filter(e => e.TYPE === 'WEEKEND')
        );
        schedule = filteredConfig.SCHEDULES.filter(e => e.TYPE === 'WEEKEND');
      } else {
        console.log(
          'Workday schedule',
          filteredConfig.SCHEDULES.filter(e => e.TYPE === 'WORKDAY')
        );
        schedule = filteredConfig.SCHEDULES.filter(e => e.TYPE === 'WORKDAY');
      }

      if (schedule.length) {
        const [firstElement] = schedule;
        schedule = firstElement;
      } else {
        console.log('Failed to select schedule!!!');
      }

      if (
        timeToSeconds(currTime) > timeToSeconds(schedule?.START_TIME) &&
        timeToSeconds(currTime) < timeToSeconds(schedule?.END_TIME)
      ) {
        scriptList = filteredConfig.SCRIPTS.map(s => s.SCRIPT);
        console.log(
          'Fell in scheduled time: scripts injected',
          'start time:',
          schedule?.START_TIME,
          'end time:',
          schedule?.END_TIME,
          'current time:',
          currTime
        );
      } else {
        console.log(
          'Did not fall in scheduled time',
          schedule?.START_TIME,
          schedule?.END_TIME,
          'current time',
          currTime
        );
      }
    }

    // Inject the scripts into DOM
    if (scriptList.length > 1) {
      // getting array values from array
      const scriptArray = scriptList.values();
      const scripT = scriptArray.next().value;
      scriptList.forEach(script => {
        const genesysscript = document.createElement('script');
        genesysscript.src = `${domainUrl}${script}`;
        document.getElementsByTagName('head')[0].appendChild(genesysscript);
        launcher.innerHTML = loadHtmlPage(`${domainUrl}${LAUNCHER_HTMLFILE_PATH}`);
        document.getElementsByTagName('body')[0].appendChild(launcher);
        document.getElementsByTagName('body')[0].appendChild(genesysCsslink);
        if (scripT.includes('genesys-chat-eir-consumer-sales')) {
          genesysScriptCsslink.href = `${domainUrl}${LAUNCHER_CONSUMER_SALES_CSSFILE_PATH}`;
          document.getElementsByTagName('body')[0].appendChild(genesysScriptCsslink);
        }
      });
    } else if (scriptList.length === 1) {
      // getting array values from array
      const scriptArray = scriptList.values();
      const script = scriptArray.next().value;

      // Append scripts
      genesysScript.src = `${domainUrl}${scriptList}`;
      document.getElementsByTagName('head')[0].appendChild(genesysScript);

      // Append common CSS file
      document.getElementsByTagName('body')[0].appendChild(genesysCsslink);

      // Append CSS files depending on scripts
      if (script.includes('genesys-chat-eir-consumer-service')) {
        // Append HTML & CSS file
        launcher.innerHTML = loadHtmlPage(`${domainUrl}${LAUNCHER_HTMLFILE_PATH}`);
        document.getElementsByTagName('body')[0].appendChild(launcher);
        genesysScriptCsslink.href = `${domainUrl}${LAUNCHER_CONSUMER_SERVICE_CSSFILE_PATH}`;
        document.getElementsByTagName('body')[0].appendChild(genesysScriptCsslink);
      } else if (script.includes('genesys-chat-eir-smb-sales')) {
        // Append HTML & CSS file
        launcher.innerHTML = loadHtmlPage(`${domainUrl}${LAUNCHER_HTMLFILE_PATH}`);
        document.getElementsByTagName('body')[0].appendChild(launcher);
        genesysScriptCsslink.href = `${domainUrl}${LAUNCHER_SMB_SALES_CSSFILE_PATH}`;
        document.getElementsByTagName('body')[0].appendChild(genesysScriptCsslink);
      }
    } else return null;
  } catch (err) {
    console.error(err.message);
  }
};
getScripts();
